// @font-face
//   font-family: 'Sarabun'
//   font-style: normal
//   font-weight: 300
//   src: local('Sarabun Light'), local('Sarabun-Light'), url(~/assets/fonts/Sarabun/Sarabun-Light.ttf) format('truetype')
//
// @font-face
//   font-family: 'Sarabun'
//   font-style: normal
//   font-weight: 400
//   src: local('Sarabun'), local('Sarabun Regular'), url(~/assets/fonts/Sarabun/Sarabun-Regular.ttf) format('truetype')
//
// @font-face
//   font-family: 'Sarabun'
//   font-style: normal
//   font-weight: 700
//   src: local('Sarabun Bold'), local('Sarabun-Bold'), url(~/assets/fonts/Sarabun/Sarabun-Bold.ttf) format('truetype')
//

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap')

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: local("Proxima Nova"), url("~@assets/fonts/ProximaNova/Proxima Nova Regular.otf") format("opentype")
  unicode-range: U+000-5FF
  font-display: auto

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 500
  font-display: swap
  src: local("ProximaNova-Medium"), url("~@assets/fonts/ProximaNova/ProximaNova-Medium.ttf") format("truetype")
  unicode-range: U+000-5FF
  font-display: auto

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 600
  font-display: swap
  src: local('Proxima Nova Semibold'), url("~@assets/fonts/ProximaNova/Proxima Nova Semibold.otf") format("opentype")

@font-face
  font-family: 'ProximaNova Semibold'
  font-style: normal
  font-weight: 600
  font-display: swap
  src: local('Proxima Nova Semibold'), url("~@assets/fonts/ProximaNova/Proxima Nova Semibold.otf") format("opentype")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 700
  font-display: swap
  src: local('Proxima Nova Bold'), url("~@assets/fonts/ProximaNova/Proxima Nova Bold.otf") format("opentype")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 800
  font-display: swap
  src: local('Proxima Nova Extrabold'), url("~@assets/fonts/ProximaNova/Proxima Nova Extrabold.otf") format("opentype")

@font-face
  font-family: 'GeneralSans'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: local('General Sans Regular'), url("~@assets/fonts/GeneralSans/GeneralSans-Regular.ttf") format("truetype")

@font-face
  font-family: 'GeneralSans'
  font-style: normal
  font-weight: 500
  font-display: swap
  src: local('General Sans Medium'), url("~@assets/fonts/GeneralSans/GeneralSans-Medium.ttf") format("truetype")

@font-face
  font-family: 'GeneralSans'
  font-style: normal
  font-weight: 600
  font-display: swap
  src: local('General Sans Semibold'), url("~@assets/fonts/GeneralSans/GeneralSans-Semibold.ttf") format("truetype")

@font-face
  font-family: 'GeneralSans'
  font-style: normal
  font-weight: 700
  font-display: swap
  src: local('General Sans Bold'), url("~@assets/fonts/GeneralSans/GeneralSans-Bold.ttf") format("truetype")
